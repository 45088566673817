// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asukkaalle-js": () => import("./../../../src/pages/asukkaalle.js" /* webpackChunkName: "component---src-pages-asukkaalle-js" */),
  "component---src-pages-asunnot-js": () => import("./../../../src/pages/asunnot.js" /* webpackChunkName: "component---src-pages-asunnot-js" */),
  "component---src-pages-asunto-js": () => import("./../../../src/pages/asunto.js" /* webpackChunkName: "component---src-pages-asunto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiitos-js": () => import("./../../../src/pages/kiitos.js" /* webpackChunkName: "component---src-pages-kiitos-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old_index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-tietosuojaseloste-js": () => import("./../../../src/pages/tietosuojaseloste.js" /* webpackChunkName: "component---src-pages-tietosuojaseloste-js" */),
  "component---src-pages-vuokrahakemus-js": () => import("./../../../src/pages/vuokrahakemus.js" /* webpackChunkName: "component---src-pages-vuokrahakemus-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */)
}

